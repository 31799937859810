// src/components/EmployeeSearch.tsx
import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Fetch_Employee_List_List_Management } from "../../redux/actions/Employee/Employee";

interface Employee {
  id: string;
  firstName: string;
  employee_id: string;
  avatarUrl: string;
}

interface EmployeeSearchProps {
  onEmployeeSelect: (employeeId: string) => void;
  onEmployeeSelectId: (user_Id: string) => void;
  label:string
}

const EmployeeSearch: React.FC<EmployeeSearchProps> = ({ onEmployeeSelect,onEmployeeSelectId,label }) => {
  const employee = useSelector((state: any) => state.emplist?.EmployeeData);
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  console.log(employee,"employeeemployeeemployee----->>>EmployeSearch");
  
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();


  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  useEffect(() => {
    console.log("callll----->>>>");
    dispatch<any>(Fetch_Employee_List_List_Management(customer_id, 100, 1, searchQuery));
  }, [searchQuery]);

  const filteredEmployees = employee.filter(
    (emp: { employee_id: string; firstName: string; }) =>
      emp.employee_id?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      emp.firstName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setIsDropdownOpen(true);
  };

  const handleOptionClick = (employee: Employee) => {
    setSearchQuery(employee.firstName);
    setIsDropdownOpen(false);
    onEmployeeSelect(employee.employee_id); // Pass the employee ID to the parent component
    onEmployeeSelectId(employee.id)
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="relative">
      <TextField
        required
        id="outlined-required"
        label={label}
        name="empId"
        placeholder="Search with Employee name & ID"
        onFocus={() => setIsDropdownOpen(true)}
        onChange={handleInputChange}
        className="w-[95%]"
        value={searchQuery}
      />
      {isDropdownOpen && (
        <div className="absolute mt-2 w-full p-4 bg-white shadow-lg rounded-lg max-h-52 overflow-y-auto z-[999]">
          {/* <input
            type="text"
            placeholder="Search with Employee name & ID"
            className="w-full p-2 mb-4 border rounded-lg"
            onChange={handleInputChange}
          /> */}
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee: Employee) => (
              <div
                key={employee.id}
                className="flex items-center p-2 mb-2 bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg z-10"
                onClick={() => handleOptionClick(employee)}
              >
                <div className="z-10">
                  <p className="font-semibold">{employee.firstName}</p>
                  <p className="text-gray-600">{employee.employee_id}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-600">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default EmployeeSearch;









// // src/components/EmployeeSearch.tsx
// import { TextField } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// //import debounce from "lodash.debounce";
// import { debounce } from "lodash";

// interface Employee {
//   id: string;
//   firstName: string;
//   employee_id: string;
//   avatarUrl: string;
// }

// interface EmployeeSearchProps {
//   onEmployeeSelect: (employeeId: string) => void;
// }

// const EmployeeSearch: React.FC<EmployeeSearchProps> = ({ onEmployeeSelect }) => {
//   const employees = useSelector((state: any) => state.emplist?.EmployeeData);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [error, setError] = useState<string | null>(null);

//   const filteredEmployees = employees.filter(
//     (emp: { employee_id: string; firstName: string; }) =>
//       emp.employee_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       emp.firstName.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.target.value;
//     setSearchQuery(value);
//     setIsDropdownOpen(true);

//     // Clear error when user starts typing
//     setError(null);
//   };

//   const handleOptionClick = (employee: Employee) => {
//     setSearchQuery(employee.firstName);
//     setIsDropdownOpen(false);
//     onEmployeeSelect(employee.employee_id); // Pass the employee ID to the parent component
//   };

//   const validateInput = debounce(() => {
//     if (searchQuery.trim() === "") {
//       setError("Employee ID is required.");
//     } else {
//       setError(null);
//     }
//   }, 500);

//   useEffect(() => {
//     validateInput();
//   }, [searchQuery]);

//   return (
//     <div className="relative">
//       <TextField
//         required
//         id="outlined-required"
//         label="Employee Id"
//         name="empId"
//         onFocus={() => setIsDropdownOpen(true)}
//         onChange={handleInputChange}
//         className="w-[95%]"
//         value={searchQuery}
//         error={!!error}
//         helperText={error}
//       />
//       {isDropdownOpen && (
//         <div className="absolute mt-2 w-full p-4 bg-white shadow-lg rounded-lg max-h-96 overflow-y-auto z-10">
//           <input
//             type="text"
//             placeholder="Search with Employee name & ID"
//             className="w-full p-2 mb-4 border rounded-lg"
//             onChange={handleInputChange}
//           />
//           {filteredEmployees.length > 0 ? (
//             filteredEmployees.map((employee: Employee) => (
//               <div
//                 key={employee.id}
//                 className="flex items-center p-2 mb-2 bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg"
//                 onClick={() => handleOptionClick(employee)}
//               >
//                 <div>
//                   <p className="font-semibold">{employee.firstName}</p>
//                   <p className="text-gray-600">{employee.employee_id}</p>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="p-2 text-gray-600">No results found</div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default EmployeeSearch;

