import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MyStatefulEditor from "../EditEditor";
import FileUpload from "../../ExpenseManagement/EditFileUoload";
import { MenuItem } from "@mui/material";
import { Fetch_Clients_List_Data } from "../../common/services/Clients";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import toast from "react-hot-toast";
import axios from "axios";
import { fetchEditProject, updateProject } from "../../common/services/Project";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original: string;
}

interface FormValues {
  project_name: string | null;
  status: string | null;
  currency: string | null;
  client: string | null;
  Estimate_cost: string | null;
  Actual_cost: string | null;
  start_date: string | null;
  end_date: string | null;
  estimate_hours: string | null;
  actual_hours: string | null;
  project_type: string | null;
  
}

interface Leave_Type {
  id: string;
  client_name: string;
}

const ProjectUpdate = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<UploadedFile[]>([]);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [startDate, setStartData] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [editorContent, setEditorContent] = useState<string>("");

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const location = useLocation();
  const id = location.state?.id;

  const [formValues, setFormValues] = useState<FormValues>({
    project_name: "",
    status: "",
    currency: "",
    client: "",
    Estimate_cost: "",
    Actual_cost: "",
    start_date: "",
    end_date: "",
    estimate_hours: "",
    actual_hours: "",
    project_type: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.project_name === "" || formValues.project_name === null)
      tempErrors.project_name = "Project Name is required";
    if (formValues.status === "" || formValues.status === null)
      tempErrors.status = "Project Status is required";
    if (formValues.currency === "" || formValues.currency === null)
    if (formValues.start_date === "" || formValues.start_date === null)
      tempErrors.start_date = "Start Date is required";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const Currency = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
  ];

  const Status = [
    { value: "upcoming", label: "Up Coming" },
    { value: "ongoing", label: "On Going" },
    { value: "completed", label: "Completed" },
    { value: "onhold", label: "On Hold" },
  ];

  const fetchLeaveType = async () => {
    //  setLoading(true);
    try {
      const response = await Fetch_Clients_List_Data(
        customer_id,
        // searchTerm,
        numOfItems,
        page
      );

      if (response.status === 200) {
        setTableData(response.data.result.rows);

        // setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    //setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, [page, numOfItems]);

  useEffect(() => {
    // setLoading(true);
    fetchEditProject(id)
      .then((data) => {
        if (data.result) {
          // setUpdateData(data);

          const taskResources = data.result.TaskResources;

          const { project_document, receipt_name } = data.result;

          const files = project_document?.map(
            (name: string, index: number) => ({
              //  originalName: name,
              url: project_document[index],
              // fileType: receipt_file_type[index],
            })
          );

          setFormValues({
            project_name: data.result.project_name,
            status: data.result.status,
            currency: data.result.currency,
            client: data.result.client_id,
            Estimate_cost: data.result.estimated_cost,
            Actual_cost: data.result.actual_cost,
            start_date: data.result.start_date,
            end_date: data.result.end_date,
            estimate_hours: data.result.estimated_hours,
            actual_hours: data.result.actual_hours,
            project_type: data.result.project_type,
          });
          setEditorContent(data.result.description);
          setStartData(dayjs(data.result.start_date));
          setEndDate(dayjs(data.result.end_date));
          // const userIds = taskResources.map((resource: any) => resource.user_id);
          // setSelectedEmployeeIds(userIds);
          setUploadedFiles(files);
          setInitialFiles(files);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);


  const submitChanges = async () => {

    setLoading(true);
    try {
      const data = await updateProject(
        id,
        formValues.project_name,
        formValues.currency,
        formValues.client,
        formValues.Estimate_cost,
        formValues.Actual_cost,
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        Number(formValues.estimate_hours),
        Number(formValues.actual_hours),
        formValues.status,
        uploadedFiles?.map((files) => files.url),
        formValues.project_type,
        editorContent.replace(/<[^>]+>/g, "")
      );

      if (data && data.result) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          //navigate(`edit-resources-details/${id}`);
          navigate("/project-management");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };


  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, project_type: e.target.value });
  };

  const { param } = useParams();

  const handleOpen = () => {
    const res = validate();
    res ? submitChanges() : toast.error("Please fill all the required fields.");
  };

  return (
    <div className="mt-6">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/project-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Project Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/project-management")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={() => handleOpen()}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize px-[15px]"
            >
              Update & Continue
            </div>
          </button>
        </div>
      </div>

      <div className="mt-10 rounded-md overflow-hidden shadow-custom bg-white">
        <div className="container p-6">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Project Name"
                placeholder="Enter Project"
                value={formValues.project_name}
                name="project_name"
                onChange={handleFrormChange}
                error={!!errors.project_name}
                helperText={errors.project_name}
              />
              <TextField
                required
                id="outlined-required"
                label="Status"
                select
                value={formValues.status}
                onChange={handleFrormChange}
                placeholder="Status"
                name="status"
                error={!!errors.status}
                helperText={errors.status}
              >
                {Status?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-required"
                label="Currency"
                select
                // placeholder="Enter Declaration"
                value={formValues.currency}
                onChange={handleFrormChange}
                name="currency"
                error={!!errors.currency}
                helperText={errors.currency}
              >
                {Currency?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Client"
                select
                value={formValues.client}
                onChange={handleFrormChange}
                placeholder="Enter Declaration"
                name="client"
              >
                {tableData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.client_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-required"
                label="Estimate Cost"
                placeholder="Enter Estimated Cost"
                value={formValues.Estimate_cost}
                onChange={handleFrormChange}
                name="Estimate_cost"
                error={!!errors.Estimate_cost}
                helperText={errors.Estimate_cost}
              />
              <TextField
                id="outlined"
                label="Actual Cost"
                placeholder="Enter Declaration"
                name="Actual_cost"
                value={formValues.Actual_cost}
                onChange={handleFrormChange}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartData(newValue)}
                  // className="w-full"
                />
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl error={!!errors.start_date} fullWidth>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartData(newValue)}
                  />
                  {errors.start_date && (
                    <FormHelperText>{errors.start_date}</FormHelperText>
                  )}
                </FormControl>
              </LocalizationProvider> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  //className="w-full"
                />
              </LocalizationProvider>
              <TextField
                id="outlined-required"
                label="Estimate Hours"
                placeholder="Enter Declaration"
                value={formValues.estimate_hours}
                onChange={handleFrormChange}
                name="estimate_hours"
              />
              <TextField
                id="outlined"
                label="Actual Hours"
                placeholder="Enter Declaration"
                name="actual_hours"
                value={formValues.actual_hours}
                onChange={handleFrormChange}
              />
            </div>

            <div className="mt-4 pl-2">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Project Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formValues.project_type}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="Billable"
                    control={<Radio />}
                    label="Billable"
                  />
                  <FormControlLabel
                    value="Non Billable"
                    control={<Radio />}
                    label="Non Billable"
                  />
                  <FormControlLabel
                    value="Revenue Generation"
                    control={<Radio />}
                    label="Revenue Generation"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="mt-6 px-2">
              <MyStatefulEditor
                content={editorContent}
                onChange={setEditorContent}
              />
            </div>

            <FileUpload
              existingFiles={initialFiles}
              onFilesChange={handleFilesChange}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ProjectUpdate;
function setSelectedEmployeeIds(userIds: any) {
  throw new Error("Function not implemented.");
}
