import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_CLIENTS_LIST } from "../api";
import moment from 'moment'

export const Fetch_Clients_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  page: number,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(`${FETCH_CLIENTS_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Clients_List_Data = (
  customer_id: number,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;

  return axios.get(`${FETCH_CLIENTS_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createClientsApi = async (
  client_name: string | null,
  email:string|null,
  country_id:string|null,
  state_id:String|null,
  address:string|null,
  phone:string|null,
  phone_code:string|null,
  point_of_contact:string|null,
  status:string|null
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    client_name: client_name,
    email:email,
    country_id:country_id,
    state_id:state_id,
    address:address,
    phone:phone,
    phone_code:phone_code,
    point_of_contact:point_of_contact,
    status:status
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/client`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};


export const fetchEditClientList = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const response = await axios.get(`${base_api}/api/v1/client/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateClients = async (
  id:string,
  client_name: string,
  email:string|null,
  country_id:string|null,
  state_id:string|null,
  address:string|null,
  phone:string|null,
  phone_code:string|null,
  point_of_contact:string|null,
  status:string|null
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    client_name: client_name,
    email:email,
    country_id:country_id,
    state_id:state_id,
    address:address,
    phone:phone,
    phone_code:phone_code,
    point_of_contact:point_of_contact,
    status:status
  };

  try {
    const response = await axios.put(
      `${base_api}/api/v1/client/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};
