import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import check from "../../image/checkmark-circle-02.svg";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import { CountryCode } from "libphonenumber-js/core";
import {
  Department_Unit,
  Designation_Manager,
  EmployeeUnit,
  Reporting_Manager,
  Role_Unit,
  Team_Manager,
} from "../../redux/actions/Employee/Employee";
import { MuiTelInput } from "mui-tel-input";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createEmployee } from "../common/services/Employee";
import toast from "react-hot-toast";
import { inputStyle } from "../../utils/helperData";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface BusinessUnit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface DepartmentUnit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Team_Unit {
  firstName: string;
  lastName: string;
  id: Number;
}
interface Report_Unit {
  firstName: string;
  lastName: string;
  id: Number;
}

interface Designation_Unit {
  name: string;
  id: Number;
}
interface Role_Data {
  role_name: string;
  id: Number;
}

interface FormValues {
  employeeCode: string;
  employeeId: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  experience: Number;
}

interface FormSelectValues {
  business: string;
  department: string;
  designation: string;
  role: string;
  team: string;
  report: string;
  empStatus: string;
  ModeOfEmp: string;
}

export const AddEmployee = () => {
  //roleManagement
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [birth, setBirth] = React.useState<Dayjs | string | null>(null);
  const [joining, setJoining] = React.useState<Dayjs | string | null>(null);
  const [department, setDepartment] = useState<DepartmentUnit[]>();
  const [bussness, setBusiness] = useState<BusinessUnit[]>();
  const [team, setTeam] = useState<Team_Unit[]>();
  const [selectReport, setSelectReport] = useState<Report_Unit[]>();
  const [designation, setDesignation] = useState<Designation_Unit[]>();
  const [roledata, setRoleData] = useState<Role_Data[]>();
  const [countryInitial, setCountryInitial] = useState<CountryCode>();
  const [nationalNumber, setNationalNumber] = useState("");
  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    employeeCode: "",
    employeeId: "",
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    experience: 0,
  });

  const [isDOB, setIsDOB] = useState<boolean | null>(false);
  const [isDOBDisplay, setIsDOBDisplay] = useState<boolean | null>(false);
  const [isJoining, setIsJoining] = useState<boolean | null>(false);
  const [isJoiningDisplay, setIsJoiningDisplay] = useState<boolean | null>(
    false
  );

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    // Fetch country based on latitude and longitude
    async function fetchCountry() {
      try {
        const response = await fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
        );
        if (response.ok) {
          const data = await response.json();
          setCountryInitial(data.countryCode);
        } else {
          console.error("Failed to fetch country.");
        }
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    }

    if (latitude !== undefined && longitude !== undefined) {
      fetchCountry();
    }
  }, [latitude, longitude]);

  const handleClose = () => {
    setOpen(false);
    navigate("/employee-management");
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCancel = () => {
    navigate("/employee-management");
  };

  const [phone, setPhone] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState("");

  //const EmployeeStatus = ["active", "inactive", "archived"];
  const EmployeeStatus = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "archived", label: "Archived" },
  ];

  const Prefix = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
  ];
  //const ModeOfEmployment = ["full_time", "part_time", "contract", "intern"];

  const ModeOfEmployment = [
    { value: "full_time", label: "Full Time" },
    { value: "part_time", label: "Part Time" },
    { value: "contract", label: "Contract" },
    { value: "internship", label: "Intern" },
  ];

  const [formSelectValues, setFormSelectValues] = useState<FormSelectValues>({
    business: "",
    department: "",
    designation: "",
    role: "",
    team: "",
    report: "",
    empStatus: "",
    ModeOfEmp: "",
  });

  const handleSelectFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFormSelectValues({
      ...formSelectValues,
      [name]: value,
    });

    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  // const handleDesignationChange = (designationId: string) => {
  //   setFormSelectValues({
  //     ...formSelectValues,
  //     designation: designationId,
  //   })
  // }

  let customer_id = 0;

  if (loginData !== null) {
    customer_id = loginData.customer_id;
  }

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  useEffect(() => {
    const fetchRoledata = async () => {
      try {
        const historyData = await dispatch<any>(
          Role_Unit(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setRoleData(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchRoledata();
  }, []);

  const fetchDesignation = async () => {
    try {
      const firstResponse = await dispatch<any>(
        Designation_Manager(customer_id, navigate, 1)
      );

      const totalCount = firstResponse?.result.count || 0;
      const totalPages = Math.ceil(totalCount / 10);

      const remainingPages = [];
      for (let num = 2; num <= totalPages; num++) {
        remainingPages.push(num);
      }

      const remainingResponses = await Promise.all(
        remainingPages.map(async (num) => {
          return await dispatch<any>(
            Designation_Manager(customer_id, navigate, num)
          );
        })
      );

      const allDesignations = [
        ...firstResponse.result.rows,
        ...remainingResponses.flatMap(
          (response) => response?.result.rows || []
        ),
      ];

      setDesignation(allDesignations);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  useEffect(() => {
    const fetchTeamManager = async () => {
      try {
        const historyData = await dispatch<any>(
          Team_Manager(Number(formSelectValues.department), navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setTeam(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchTeamManager();
  }, [formSelectValues.department]);

  useEffect(() => {
    const fetchReporting = async () => {
      try {
        const historyData = await dispatch<any>(
          Reporting_Manager(customer_id, navigate)
        );

        if (historyData) {
          const recentThree = historyData?.result.userList.rows;
          setSelectReport(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchReporting();
  }, []);

  const ConditionalComponent: React.FC = () => {
    const location = useLocation();

    if (location.pathname === "/add-employee") {
      return <div>Employee details added successfully!</div>;
    } else if (location.pathname === "/edit-employee") {
      return <div>Employee details updated successfully!</div>;
    } else {
      return <div>Default Content</div>;
    }
  };

  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [errorsSelect, setErrorsSelect] = useState<Partial<FormSelectValues>>(
    {}
  );

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (!formValues.prefix.trim()) tempErrors.prefix = "Prefix is required";
    if (!formValues.firstName.trim())
      tempErrors.firstName = "First Name is required";
    if (!formValues.lastName.trim())
      tempErrors.lastName = "Last Name is required";
    if (!formValues.email.trim()) tempErrors.email = "Email is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const selectValidate = (): boolean => {
    let tempErrors: Partial<FormSelectValues> = {};

    if (formSelectValues.business === "")
      tempErrors.business = "Business Unit is required";
    if (formSelectValues.department === "")
      tempErrors.department = "Department Unit is required";
    setErrorsSelect(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handlePhoneChange = (value: string, info: any) => {
    setPhone(value);
    setNationalNumber(info?.nationalNumber);
    setCountryCode(`+${info?.countryCallingCode}`);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const postData = async () => {
    try {
      await createEmployee(
        formValues.employeeCode,
        formValues.employeeId,
        formValues.email,
        nationalNumber,
        formValues.prefix,
        formValues.firstName,
        formValues.middleName,
        formValues.lastName,
        countryCode,
        Number(formSelectValues.business),
        customer_id,
        Number(formSelectValues.role),
        Number(formSelectValues.department),
        Number(formSelectValues.designation),
        Number(formSelectValues.team),
        Number(formSelectValues.report),
        formSelectValues.empStatus,
        formSelectValues.ModeOfEmp,
        formValues.experience,
        moment(birth?.toString()).format("YYYY-MM-DD"),
        moment(joining?.toString()).format("YYYY-MM-DD")
      );

      setOpen(true);

      // dispatch(
      //   userManagementActions.resetUserRoleData({
      //     count: 0,
      //     rows: [],
      //     currentPage: 1,
      //     itemsPerPage: 10,
      //     isNext: true,
      //   })
      // );

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/employee-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const handleClick = async () => {
    !isDOB && setIsDOBDisplay(true);
    !isJoining && setIsJoiningDisplay(true);
    try {
      validate();
      selectValidate();
      await postData();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <img style={{ margin: "auto" }} src={check} alt="" />
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              <ConditionalComponent />
            </Typography>
          </Box>
        </Modal>
      </div>

      <div>
        <div className="bg-[#F9F9F9] px-4 pt-6 pb-12 h-full">
          <div className="flex flex-row items-center h-12 justify-between">
            <div className="flex flex-row gap-[13px] justify-center items-center">
              <button onClick={() => navigate("/employee-management")}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Add Employee Details
              </span>
            </div>
            <div className="flex flex-start gap-5">
              <button
                onClick={handleCancel}
                className="flex w-[148px] rounded-lg p-[13.18px_50px_14.82px_51px] justify-center items-center  borderButton h-12"
                //onClick={(event) => handleCurrentPageChange("table", event)}
              >
                <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Cancel
                </div>
              </button>
              <button
                type="submit"
                form="form"
                className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
                onClick={handleClick}
              >
                <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                  Save
                </div>
              </button>
            </div>
          </div>
          <div className="mt-10 bg-white shadow-custom rounded-md">
            <div className="container mx-auto p-4">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1 },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="grid grid-cols-3 gap-4">
                  <TextField
                    required
                    id="outlined-required"
                    label="Employee Code"
                    name="employeeCode"
                    defaultValue=" "
                    value={formValues.employeeCode}
                    onChange={handleFrormChange}
                    // error={!!errors.employeeCode}
                    // helperText={errors.employeeCode}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label="Employee Id"
                    defaultValue=" "
                    name="employeeId"
                    value={formValues.employeeId}
                    onChange={handleFrormChange}
                    // error={!!errors.employeeId}
                    // helperText={errors.employeeId}
                  />
                  <TextField
                    select
                    id="outlined-required"
                    label="Prifix"
                    defaultValue=" "
                    name="prefix"
                    value={formValues.prefix}
                    onChange={handleFrormChange}
                  >
                    {Prefix.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    label="First Name"
                    defaultValue=" "
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleFrormChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                  <TextField
                    id="outlined-required"
                    label="Middle Name"
                    defaultValue=" "
                    name="middleName"
                    value={formValues.middleName}
                    onChange={handleFrormChange}
                  />
                  <TextField
                    id="outlined-required"
                    label="Last Name"
                    defaultValue=" "
                    name="lastName"
                    value={formValues.lastName}
                    onChange={handleFrormChange}
                  />
                  <TextField
                    required
                    id="outlined-required"
                    label="Email"
                    defaultValue=" "
                    name="email"
                    value={formValues.email}
                    onChange={handleFrormChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <div className="flex flex-row mt-1">
                    <MuiTelInput
                      required
                      label="Phone Number"
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      defaultCountry={countryInitial}
                      focusOnSelectCountry
                      autoComplete="off"
                      className="w-full h-[2.5rem] border-opacity-30 rounded-[3px] border border-[#262626] px-2"
                      // required
                      // error={isValid[`phone`]}
                      // helperText={
                      //   isValid[`phone`] === 2
                      //     ? "This field is required!"
                      //     : isValid[`phone`] === 1
                      //     ? "Length must be of 8-16 characters long!"
                      //     : ""
                      // }
                      InputLabelProps={{
                        shrink: true,
                        // sx: labelStyle,
                      }}
                      InputProps={{
                        sx: {
                          ...inputStyle,
                          alignItems: "center",
                          flexDirection: "row",
                          // marginLeft: "20px",
                          marginBottom: "8px",
                          borderRadius: "3px",
                          height: "4.5rem",
                          // width: "300px",
                          "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px #fff inset",
                            WebkitTextFillColor: "inherit",
                          },
                        },
                      }}
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                  </div>

                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Business Unit"
                    name="business"
                    defaultValue="hello"
                    // value={selectedDepartment}
                    // onChange={handleChange}
                    variant="outlined"
                    value={formSelectValues.business}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.business}
                    helperText={errorsSelect.business}
                  >
                    {bussness?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    name="department"
                    label="Department"
                    defaultValue="hello"
                    //  sx={{ height: '300px' }}
                    value={formSelectValues.department}
                    onChange={handleSelectFormChange}
                    error={!!errorsSelect.department}
                    helperText={errorsSelect.department}
                  >
                    {department?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Designation"
                    defaultValue="hello"
                    name="designation"
                    value={formSelectValues.designation}
                    onFocus={fetchDesignation}
                    onChange={handleSelectFormChange}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {designation?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Role"
                    name="role"
                    defaultValue="hello"
                    value={formSelectValues.role}
                    onChange={handleSelectFormChange}
                  >
                    {roledata?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.role_name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-required"
                    select
                    label="Team Manager"
                    defaultValue="hello"
                    name="team"
                    value={formSelectValues.team}
                    onChange={handleSelectFormChange}
                  >
                    {team?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Reporting Manager"
                    defaultValue="hello"
                    name="report"
                    value={formSelectValues.report}
                    onChange={handleSelectFormChange}
                  >
                    {selectReport?.map((option) => (
                      <MenuItem
                        key={Number(option.id)}
                        value={Number(option.id)}
                      >
                        {option.firstName} {option.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Employee Status"
                    defaultValue="hello"
                    name="empStatus"
                    value={formSelectValues.empStatus}
                    onChange={handleSelectFormChange}
                  >
                    {EmployeeStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Mode Of Employment"
                    defaultValue="hello"
                    name="ModeOfEmp"
                    value={formSelectValues.ModeOfEmp}
                    onChange={handleSelectFormChange}
                  >
                    {ModeOfEmployment.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    id="outlined-required"
                    label="Years Of Experience"
                    defaultValue=" "
                    name="experience"
                    value={formValues.experience}
                    onChange={handleFrormChange}
                  />

                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date Of Birth"
                        value={birth}
                        onChange={(newValue) => {
                          setBirth(newValue);
                          setIsDOB(true);
                          setIsDOBDisplay(false);
                        }}
                        maxDate={dayjs()}
                        className="w-full"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isDOBDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isDOBDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isDOBDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isDOBDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isDOBDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {isDOBDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        Date of Birth is required
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date Of Joining"
                        value={joining}
                        onChange={(newValue) => {
                          setJoining(newValue);
                          setIsJoining(true);
                          setIsJoiningDisplay(false);
                        }}
                        maxDate={dayjs()}
                        className="w-full"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isJoiningDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isJoiningDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isJoiningDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isJoiningDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isJoiningDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {isJoiningDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        Date of Joining is required
                      </span>
                    )}
                  </div>

                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label="Date Of Leaving"
                        value={leaving}
                        onChange={(newValue) => setLeaving(newValue)}
                        className="w-full"
                      />
                    </DemoContainer>
                  </LocalizationProvider> */}

                  {/* <TextField
                    required
                    id="outlined-required"
                    select
                    label="Time Zone"
                    defaultValue="hello"
                  />
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Shift Start Time"
                    defaultValue="hello"
                  />
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Shift End Time"
                    defaultValue="hello"
                  /> */}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
